<template>
    <b-modal
        id="modalFeedbackSettingsEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="$emit('hidden')"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left">
            <div>
                <div class="form-group">
                    <b-form-checkbox v-model="settings.enabled" switch>
                        Display pop-up
                    </b-form-checkbox>
                </div>
                <div class="form-group">
                    <label for="title">
                        The pop-up will be displayed to the user after the
                        number of minutes you set here
                    </label>
                    <input
                        id="time"
                        v-model="settings.time"
                        :class="{ 'is-invalid': $v.settings.time.$error }"
                        type="number"
                        min="0"
                        step="0.1"
                        class="form-control"
                        placeholder="Enter number of minutes"
                    />
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitTimeHandler"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import 'flatpickr/dist/flatpickr.css';
import { required, minValue } from 'vuelidate/lib/validators';

export default {
    props: {
        currentSettings: {
            type: Object,
            required: false,
            default: null
        },
        modalTitle: {
            type: String,
            required: false,
            default: 'Edit NPS settings'
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Save'
        }
    },

    data() {
        return {
            settings: {
                time: 0,
                enabled: false
            }
        };
    },

    validations: {
        settings: {
            time: {
                required,
                minValue: minValue(0)
            }
        }
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('modalFeedbackSettingsEdit');

            this.settings = {
                time: 0,
                enabled: false
            };
        },

        async openModalHandler() {
            await this.$nextTick();

            this.settings = { ...this.currentSettings };
        },

        submitTimeHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.$emit('update-settings', this.settings);

            this.$v.$reset();
            this.hideModal();
        }
    }
};
</script>
