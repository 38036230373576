<script>
import { mapActions } from 'vuex';
import TableView from '@components/elements/TableView';
import ModalFeedbackSettingsEdit from '@components/feedback/ModalFeedbackSettingsEdit';

export default {
    page: {
        title: 'NPS'
    },

    components: {
        ModalFeedbackSettingsEdit
    },

    extends: TableView,

    data() {
        return {
            pageTitle: 'NPS',
            feedbackSettings: {
                enabled: false,
                time: 0
            }
        };
    },

    computed: {
        additionalComponents() {
            return [
                {
                    name: 'modal-feedback-settings-edit',
                    props: {
                        currentSettings: this.feedbackSettings.value,
                        modalTitle: 'Edit NPS settings',
                        buttonText: 'Save'
                    },
                    events: {
                        'update-settings': this.updateFeedbackSettingsHandler
                    }
                }
            ];
        },

        labels() {
            return [
                { value: 'rating', width: '200px' },
                { value: 'message', width: '200px' },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                }
            ];
        },

        options() {
            return {
                searchInput: false,
                clickableRows: false,
                sort: true,
                filters: true,
                addButtonText: 'Edit settings',
                addButtonIconClass: 'fas fa-wrench'
            };
        },

        includeDeleteAction() {
            return false;
        }
    },

    async mounted() {
        await this.fetchSettings();
    },

    methods: {
        ...mapActions({
            getItems: 'feedback/index',
            getSetting: 'settings/getSettingByKey',
            updateSetting: 'settings/updateSettingsByTags'
        }),

        onAddButtonClick() {
            this.$bvModal.show('modalFeedbackSettingsEdit');
        },

        async fetchSettings() {
            try {
                const response = await this.getSetting({
                    key: 'feedback'
                });

                this.feedbackSettings = { ...response };
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async updateFeedbackSettingsHandler(settings) {
            try {
                const updatedSettings = {
                    ...this.feedbackSettings,
                    value: settings
                };

                await this.updateSetting({
                    tag: 'feedback',
                    settings: [updatedSettings]
                });

                this.fetchSettings();

                this.$bvToast.toast('NPS settings have been updated', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                let message = '';

                if (err.response.data.errors) {
                    err.response.data.errors.forEach(error => {
                        message += error + '. ';
                    });
                } else {
                    message = 'Something went wrong!';
                }

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        }
    }
};
</script>
